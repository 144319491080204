import React from 'react';
import './whatsNext.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faGauge, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';


library.add( faRobot, faGauge, faVolumeHigh );

export default function WhatsNext() {
    return (
        <div className='whatsNextContainer'>
            <div>
                <h2>What's Next?</h2>
            </div>
            <div className='updatesContainer'>
                <div className='updates'>
                    <FontAwesomeIcon icon="fa-solid fa-gauge" className='updatesIcon dashboard' />
                    <h3>Enhanced Dashboards</h3>
                    <p>School/District-wide reporting to assess efficacy
                        rates across large numbers of students!</p>
                </div>
                <div className='updates'>
                    <FontAwesomeIcon icon="fa-solid fa-robot" className='updatesIcon community' />
                    <FontAwesomeIcon icon="fa-solid fa-robot" className='updatesIcon community2' />
                    <FontAwesomeIcon icon="fa-solid fa-robot" className='updatesIcon community3' />
                    <h3>Community Platform</h3>
                    <p>A platform for educators to create, share, save, and export 
                        stories based on topics, ideas, and phonemic objectives!</p>
                </div>
                <div className='updates'>
                    <FontAwesomeIcon icon="fa-solid fa-volume-high" className='updatesIcon audio' />
                    <h3>Automatic Assessment</h3>
                    <p>Automatic assessments of lessons based on high
                         confidence transcriptions!</p>
                </div>
            </div>
        </div>
    )
}