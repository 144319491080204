import React from "react";
import '../css/legalDocs.css'

export default function TermsAndConditions () {
    return (
        <div className="legalDoc">
            <h1>TERMS AND CONDITIONS OF USE</h1>
            <p>Your access and use of the Rising Tide Learning LLC World Wide Web site (the Site) is subject to the following terms and conditions and all applicable laws. By accessing and browsing this Site, you accept and agree, without limitation or qualification, the Terms and Conditions of Use and acknowledge that to the extent any other agreement between you and Rising Tide Learning LLC conflicts with the terms of this Agreement, such agreement is superseded and of no force or effect.</p>
            <h2>Intellectual Property</h2>
            <p>All material included on this site, such as text, graphics, logos, button icons, images, audio clips, and software (Material), is the property of Rising Tide Learning LLC or its content suppliers and protected by U.S. and international copyright and trademark laws. All material on this Site is the exclusive property of Rising Tide Learning LLC, and protected by U.S. and international copyright laws. All software and/or automated databases used on this Site are the property of Rising Tide Learning LLC or its software suppliers and protected by U.S. and international copyright laws. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this Site is strictly prohibited.</p>
            <h2>Limited Liability</h2>
            <p>Rising Tide Learning LLC is not responsible for technical, hardware or software failures of any kind, lost or unavailable network connections, or incomplete, garbled or delayed computer transmissions. Under no circumstances will Rising Tide Learning LLC or its suppliers be liable for any damage or injury that results from the use or reliance of the materials or information contained on this Site. Some jurisdictions prohibit the exclusion or limitation of liability for consequential or incidental damages, in which case the above limitation may not apply to you.</p>
            <p>While the material contained in the BabbleBot.xyz World Wide Web site has been checked for accuracy, and with the understanding that such materials do not infringe on any copyrights or other legal rights, all material is provided without any express or implied warranties of any kind, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose or non-infringement.</p>
            <p>Neither Rising Tide Learning LLC nor its affiliates assume responsibility for any errors or omissions in the material. In no event shall Rising Tide Learning LLC or its affiliates be liable for any special, indirect or consequential damages resulting from any use or performance of or content errors or omissions in the material, even if notified in advance of the potential for such damages. All users of the material agree that access to and use of the material is subject to the terms and conditions stated in this legal notice, as well as all applicable laws, and such access and use is at the user's own risk. Rising Tide Learning LLC may provide links to other sites that are not maintained by Rising Tide Learning LLC. Rising Tide Learning LLC does not endorse those sites and is not responsible for the content of such other sites.</p>
            <h2>Applicable Law</h2>
            <p>These terms and conditions are governed and interpreted pursuant to the laws of the State of Rising Tide Learning LLC STATE, United States of America, notwithstanding any principals of conflicts of law. If any part of these terms and conditions is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions. Rising Tide Learning LLC can revise these terms and conditions at any time without prior notice by updating this posting.</p>
        </div>
    )
}