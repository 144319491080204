import React from "react";
import './quickData.css';

export default function QuickData (
    studentCount
) {
    return (
        <section className="quickDataContainer">
            <div className="quickDataCard">
                <h4>Class Size</h4>
                <p>{studentCount.studentCount.length} Students</p> {/* double object, studentCount is the object but also the key for the array contained in studentCount */}
            </div>
            <div className="quickDataCard">
                <h4>Avg. Read Time</h4>
                <p>4 minutes 17 seconds</p>
            </div>
            <div className="quickDataCard">
                <h4>Struggling</h4>
                <p>Kyczar Aalbregtse</p>
            </div>
            <div className="quickDataCard">
                <h4>Excelling</h4>
                <p>Connor Heitman</p>
            </div>
        </section>
    )
}