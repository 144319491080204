import React from 'react';
import './features.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench, faBookOpen, faPencil } from '@fortawesome/free-solid-svg-icons';



library.add( faScrewdriverWrench, faBookOpen, faPencil );

export default function Features() {
    return (
        <div className='featuresContainer'>
            <div className='feature buildClass'>
                <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" className='featureIcon'/>
                <h3>Build a Class</h3>
                <p>Create an account and upload your class roster. From there, students can login and 
                    begin learning using the Science of Reading and AI!</p>
            </div>
            <div className='feature createStory'>
                <FontAwesomeIcon icon="fa-solid fa-book-open" className='featureIcon'/>
                <h3>Create Unique Stories</h3>
                <p>Students can login and start creating stories right away, selecting their topics 
                    from our wide list of characters and places.</p>
            </div>
            <div className='feature assessStudent'>
                <FontAwesomeIcon icon="fa-solid fa-pencil" className='featureIcon'/>
                <h3>Assess Students</h3>
                <p>Track student progression and pinpoint areas of weakness with our audio recordings 
                    and easy to use annotation system.</p>
            </div>
        </div>
    );
}