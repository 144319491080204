import React from 'react';
import { Link } from 'react-router-dom';
import './startToday.css';


export default function StartToday() {
    return (
        <div className='startTodayContainer'>
            <h2>Why not start today?</h2>
            <p>If you have any questions regarding BabbleBot, or would like to know how your classroom can get started
                using BabbleBot, feel free to email us.
                Team BabbleBot will get back to you as soon as possible!
            </p>
            <a className='ctaButton' href='mailto:  kyczar@babblebot.xyz, connor@babblebot.xyz'>Get in Touch!</a>
        </div>
    )
}