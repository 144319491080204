import { collection, getDocs } from "firebase/firestore";
import React, { useEffect } from "react";
import { firestore } from "../../firebase";

export default function FetchBadges(
    studentDocumentID = '',
    teacherDocumentID = '',
) {

    useEffect(() => {
        const collectBadges = async () => {
            try {
                console.log('collecting badges');

                const studentCollectionRef = collection(firestore, 'users', studentDocumentID.teacherDocumentID, 'students', studentDocumentID.studentDocumentID, 'objectives')
                const objectivesSnapshot = await getDocs(studentCollectionRef).then(() => {
                    const objectivesData = objectivesSnapshot.data();
                
                    console.log("Objective Data: ", objectivesData);
                });
            } catch {
                console.error();
            }
        }

        collectBadges();
    })

    return (
        <div className='badgesContainer'>
            <h3>Badges</h3>
                <div>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>
        </div>
    )
}