import React from "react";
import BabbleBot from '../../images/babblebot.png';
import './pageBreak.css';

export default function PageBreak() {
    return  (
        <section className="pageBreakSection">
            <hr />
            <img src={BabbleBot} />
            <hr />
        </section>
    )
}