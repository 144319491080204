import React from "react";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale, // x-axis
    LinearScale, // y-axis
    PointElement,
    Filler
} from 'chart.js';
import './classStats.css';

import {Doughnut, Line} from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);



export default function ClassStats (
    classObjectivesKeys,
    classObjectivesValues,
    unitScores,
    unitNames
) {

    const totalStudents = (studentArray) => {
        let total = 0;
        for(let i = 0; i <= studentArray.length; i++){
            total = total + i;
        }
        return total;
    }

    totalStudents(classObjectivesKeys.classObjectivesValues);


    const doughnutChartData = {
        labels: classObjectivesKeys.classObjectivesKeys,
        datasets: [{
            label: `Students`,
            data: classObjectivesKeys.classObjectivesValues,
            backgroundColor: ['#38023B', '#BBD5ED', '#CCFFCB', '#FFD700', '#FF6347', '#9370DB', '#87CEEB', '#90EE90', '#F4A460', '#FF69B4'],
            borderColor: ['white'],
            borderWidth: 5,
        }]
    };

    const doughnutChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'center'
            }
        },
        hoverOffset: 10,
        aspectRatio: 1,
        borderRadius: 10,
        animation: {
            animateScale: true
        }
    };

    console.log('classstats unit names', unitNames);
    console.log('classstats unit score', unitScores);

    const lineChartData = {
        labels: unitNames,  // x-axis labels from the unitNames array
        datasets: [{
            label: 'Average Scores',  // Legend title
            data: unitScores,  // y-axis data from the unitScores array
            fill: false,  // No fill under the line
            borderColor: 'rgb(75, 180, 192)',  // Line color
            tension: 0.3  // Line tension
        }],
    };
    
    const lineChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    title: {
                        display: true,
                        text: 'Average Scores'  // Legend title
                    }
                }
            }
        },
        scales: {
            // y-axis configuration
            y: {
                type: 'linear',  // Ensure it's a linear scale
                position: 'left',
                min: 0,
                max: 100,
                title: {
                    display: true,
                    text: 'Scores'  // y-axis label
                }
            },
            // x-axis configuration
            x: {
                type: 'category',  // x-axis is a category type
                labels: unitNames,  // x-axis labels from unitNames array
                title: {
                    display: true,
                    text: 'Objectives'  // x-axis label
                }
            }
        }
    };


    return (
        <div className="classStatsContainer">
            <div className="unitChart">
                <h3>Objectives Overview</h3>
                <Doughnut
                    data = {doughnutChartData}
                    options={doughnutChartOptions}
                ></Doughnut>             
            </div>

            <div className="horizontalCards">
                <h3>Average Grades</h3>
                <Line
                    data = {lineChartData}
                    options = {lineChartOptions}
                ></Line>
            </div>
        </div>
    )
}