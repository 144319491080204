import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../firebase.js';
import { doc, getDoc } from "firebase/firestore";

import ClassFetch from '../components/teacher/teacherPageFetch.jsx';
import Sidebar from '../components/teacher/sideBar.jsx';
import QuickData from '../components/teacher/quickData.jsx';
import '../components/teacher/teacher.css';
import FirstTimeClassroomSetup from '../components/teacher/createClassroom.jsx';

const TeacherDash = () => {
    const [user, setUser] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [classCode, setClassCode] = useState("");
    const [teacherDocumentID, setTeacherDocumentID] = useState("");
    const [classConfigured, setClassConfigured] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSetupModal, setShowSetupModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                console.log("User authenticated:", currentUser.email);
                setUser(currentUser);
            } else {
                setUser(null);
                navigate('/Signup');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        const fetchTeacherData = async () => {
            try {
                if (!user) {
                    console.error("No user authenticated");
                    return;
                }

                console.log("Fetching document for user:", user.email);
                const teacherDocRef = doc(firestore, 'users', user.uid);
                const teacherDocSnapshot = await getDoc(teacherDocRef);
                if (teacherDocSnapshot.exists()) {
                    const userData = teacherDocSnapshot.data();
                    setFirstName(userData.firstName);
                    setLastName(userData.lastName);
                    setClassCode(userData.classCode);
                    setTeacherDocumentID(user.uid);
                    setClassConfigured(userData.classConfigured);
                    console.log("Teacher Document ID set:", user.uid);
                } else {
                    console.error("No document found for user:", user.uid);
                }
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        if (user) {
            fetchTeacherData();
        }
    }, [user]);

    const handleClassroomSetupComplete = () => {
        setClassConfigured(true);
        setShowSetupModal(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return null; // or a loading spinner
    }


    return (
        <div className='teacherDashContainer' id='teacherDash'>
            {classConfigured === false ? (
                <div>
                    <h2>You have no classroom set up!</h2>
                    <button onClick={() => setShowSetupModal(true)}>Create my Classroom Now!</button>
                </div>
            ) : (
                <>
                    <Sidebar 
                        firstName={firstName} 
                        lastName={lastName} 
                        classCode={classCode} 
                        teacherDocumentID={teacherDocumentID} 
                    />
                    <ClassFetch 
                        teacherDocumentID={teacherDocumentID}
                        firstName={firstName}
                    />
                </>
            )}

            {showSetupModal && (
                <FirstTimeClassroomSetup
                    user={user}
                    onClose={() => setShowSetupModal(false)}
                    onClassroomSetup={handleClassroomSetupComplete}
                />
            )}
        </div>
    );
};

export default TeacherDash;
